import React from 'react';
import { connect } from 'react-redux';

import { getLocalisedPrice } from 'Utils/currencyUtils';
import { formatPrice } from 'Utils/gen';
import { getCurrenciesMap, getCurrentLanguageCode } from 'Utils/stateUtils';

type PriceElementsProps = {
	className?: string;
	currencyCode?: string;
	amount?: number;
	currenciesMap?: any;
	lang: string;
};

const PriceElements = ({
	className,
	currencyCode,
	currenciesMap,
	amount,
	lang,
}: PriceElementsProps) => {
	if (!currenciesMap || !currencyCode || !amount) return null;
	const currency = currenciesMap?.[currencyCode];
	if (!currency) return null;
	return (
		<span className={className}>
			{getLocalisedPrice(amount, currency, lang)}
		</span>
	);
};

const mapStateToProps = (state: any, _ownProps: any) => ({
	currenciesMap: getCurrenciesMap(state),
	lang: getCurrentLanguageCode(state),
});

const Price = connect(mapStateToProps)(PriceElements);

export default Price;
