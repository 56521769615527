import React, { ReactNode } from 'react';
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';
import Price from 'Components/common/price';

import { getSavingsPercent, getSavingsString } from 'Utils/productUtils';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const StyledPriceBlock = styled.div<{ $isPinnedCard: boolean }>`
	display: grid;
	grid-template-areas: 'scratch scratch' 'price saved';
	justify-content: left;
	grid-row-gap: 0.25rem;
	grid-column-gap: 0.25rem;
	align-items: center;
	text-transform: uppercase;
	margin-right: 0.625rem;

	.tour-price {
		grid-area: price;
		color: ${colors.GREY['66']};
		margin-right: 0.125rem;
		${({ $isPinnedCard }) =>
			$isPinnedCard
				? getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)
				: getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE_HEAVY)};
	}

	.tour-scratch-price {
		grid-area: scratch;
		color: ${colors.GREY['66']};
		${({ $isPinnedCard }) =>
			$isPinnedCard
				? getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)
				: getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_XS)};
		text-transform: lowercase;

		& > span {
			color: ${colors.GREY['66']};
			${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_XS)};
			text-transform: uppercase;
			text-decoration: line-through;
		}
	}
`;

export const SavedTag = styled.div`
	padding: 0.125rem 0.25rem 0.1875rem 0.25rem;
	color: ${colors.WHITE};
	border-radius: 0.25rem;
	letter-spacing: 0.0125rem;
	${getFontDetailsByLabel(TYPE_LABELS.MISC_TAG_REGULAR)};
	background-color: ${colors.GREEN_3};
	text-transform: none;
	text-align: center;
	grid-area: saved;
`;

const StyledUnavailableTag = styled.div`
	padding: 0.25rem 0.5rem;
	margin-top: -0.1875rem;
	display: inline-block;
	border-radius: 0.125rem;
	text-transform: uppercase;
	background: ${colors.GREY.G7};
	color: ${colors.GREY['66']};
	${getFontDetailsByLabel(TYPE_LABELS.MISC_OVERLINE)};
`;

const UnavailableTag = () => (
	<StyledUnavailableTag>{strings.CMN_UNAVAILABLE}</StyledUnavailableTag>
);

type PriceBlockProps = {
	showScratchPrice?: boolean;
	listingPrice: any;
	prefix?: boolean;
	showSavings?: boolean;
	cashback?: string | null;
	saveStringOverride?: string;
	noPriceFallback?: ReactNode | React.Component | null;
	addOfferMetaData?: boolean;
	isPinnedCard?: boolean;
	showBestDiscount?: boolean;
};

const PriceBlock = ({
	listingPrice,
	cashback,
	showScratchPrice = true,
	prefix = true,
	showSavings = false,
	saveStringOverride = '',
	noPriceFallback = null,
	addOfferMetaData = false,
	isPinnedCard = false,
	showBestDiscount = false,
}: PriceBlockProps) => {
	if (!listingPrice)
		return noPriceFallback ? <>{noPriceFallback}</> : <UnavailableTag />;

	const {
		originalPrice,
		finalPrice,
		bestDiscount,
		currencyCode,
		otherPricesExist,
	} = listingPrice || {};

	const savingsPercentage = showBestDiscount
		? bestDiscount
		: getSavingsPercent({ originalPrice, finalPrice });

	const savings = listingPrice && showSavings ? savingsPercentage : 0;
	const savingsString = getSavingsString(
		savings,
		cashback,
		saveStringOverride,
	);
	const scratchPriceAvailable = originalPrice > finalPrice;
	const getStyledPriceBlockInternalMarkup = () => (
		<>
			<Conditional if={otherPricesExist || scratchPriceAvailable}>
				<div className='tour-scratch-price'>
					{prefix && otherPricesExist ? strings.FROM + ' ' : ''}

					<Conditional if={showScratchPrice && scratchPriceAvailable}>
						<Price
							currencyCode={currencyCode}
							amount={originalPrice}
						/>
					</Conditional>
				</div>
			</Conditional>
			<Price
				className='tour-price'
				currencyCode={currencyCode}
				amount={finalPrice}
				$isPinnedCard={isPinnedCard}
			/>

			<Conditional
				if={
					(savingsPercentage >= 3 || savingsString) &&
					showScratchPrice
				}
			>
				<SavedTag>{savingsString}</SavedTag>
			</Conditional>
		</>
	);

	return addOfferMetaData ? (
		<StyledPriceBlock
			itemScope
			itemProp='offers'
			itemType='https://schema.org/Offer'
			$isPinnedCard={isPinnedCard}
		>
			<meta itemProp='price' content={finalPrice} />
			<meta itemProp='priceCurrency' content={currencyCode} />
			{getStyledPriceBlockInternalMarkup()}
		</StyledPriceBlock>
	) : (
		<StyledPriceBlock $isPinnedCard={isPinnedCard}>
			{getStyledPriceBlockInternalMarkup()}
		</StyledPriceBlock>
	);
};

export default PriceBlock;
