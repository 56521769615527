import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

import type { CollectionVideo } from 'Containers/mobile/collectionsPage';

import Conditional from 'Components/common/conditional';
import { Swiper } from 'Components/common/customSwiper';
import type {
	SwiperRefActions,
	SwiperSlideChangedCbProps,
} from 'Components/common/customSwiper/interface';
import Image from 'Components/common/image';
import SwiperIndicator from 'Components/common/swiperIndicator';

import useOnScreen from 'Hooks/useOnScreen';
import usePageLoaded from 'Hooks/usePageLoaded';
import { trackEvent } from 'Utils/analytics';
import PlatformUtils from 'Utils/platformUtils';
import { isBot as isBotfromStateUtils } from 'Utils/stateUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';

import {
	LeftArrowShadow,
	PagintorContainer,
	RightArrowShadow,
	StyledContainer,
} from './style';

const Video = dynamic(
	() => import(/* webpackChunkName: 'Video' */ 'Components/common/video'),
);

type ImageObj = {
	url: string;
	altText?: string;
	alt?: string;
	title?: string;
};

type Props = {
	id: string | number;
	images: Array<ImageObj>;
	video: CollectionVideo | undefined;
	width?: number;
	height?: number;
	isCardActive: boolean;
	priority?: boolean;
	shouldDisablePointerEvents: boolean;
	sectionDataPayload?: Record<string, any>;
};

const dotsLimit = 5;
const mobileDotSize = 6;
const mobileDotMargin = 3;
const mobileTabSize = 24;
const mobileTabMargin = 3;

const isDesktop = PlatformUtils.isDesktop();

const ProductCardMediaCarousel = ({
	id,
	images,
	video,
	width,
	height,
	isCardActive,
	priority = false,
	shouldDisablePointerEvents = false,
	sectionDataPayload = {},
}: Props) => {
	const { isBot } = useSelector(state => ({
		isBot: isBotfromStateUtils(state),
	}));

	const containerRef = useRef<HTMLDivElement>(null);
	const slider = useRef<SwiperRefActions>(null);

	const [currentPaginationIndex, setCurrentPaginationIndex] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isFirstSlideTracked, setIsFirstSlideTracked] = useState(false);
	const [areAllImagesRendered, setAreAllImagesRendered] = useState(false);

	const hasPageLoaded = usePageLoaded();
	const isContainerOnScreen = useOnScreen({
		ref: containerRef,
		options: {
			threshold: 0.8,
		},
		disconnectAfterOneIntersection: true,
	});

	const autoPlayTimeMs =
		currentPaginationIndex === 0 && video?.url ? 15000 : 3000;
	// Disable carousel for len(images) <= 2
	const productImages =
		(isCardActive || areAllImagesRendered || isBot) && images.length > 2
			? images
			: images.slice(0, 1);
	const shouldShowCarousel = isCardActive && productImages.length > 1;

	const trackSlideChange = useCallback(
		({
			index,
			isScrollCustom,
		}: {
			index: number;
			isScrollCustom: boolean;
		}) => {
			trackEvent({
				eventName: ANALYTICS_EVENTS.VIDEO.PRODUCT_CARD_IMAGE_VIEWED,
				Ranking: index + 1,
				[ANALYTICS_PROPERTIES.TOUR_GROUP_ID]: id,
				[ANALYTICS_PROPERTIES.TRIGGERED_BY]: isScrollCustom
					? 'Automatic'
					: 'User',
				...sectionDataPayload,
			});
		},
		[id],
	);

	const onSlideChanged = ({
		index,
		isScrollCustom,
	}: SwiperSlideChangedCbProps) => {
		setCurrentIndex(index);
		if (isScrollCustom !== null) {
			trackSlideChange({
				index,
				isScrollCustom: isScrollCustom as boolean,
			});
		}
	};

	const onArrowShadowClick = ({
		event,
		direction,
	}: {
		event: MouseEvent<HTMLDivElement>;
		direction: 'left' | 'right';
	}) => {
		event?.stopPropagation();
		direction === 'left'
			? slider?.current?.prevSlide()
			: slider?.current?.nextSlide();
	};

	useEffect(() => {
		if (
			isContainerOnScreen &&
			currentPaginationIndex === 0 &&
			!isFirstSlideTracked
		) {
			trackSlideChange({ index: 0, isScrollCustom: true });
			setIsFirstSlideTracked(true);
		}
	}, [
		currentPaginationIndex,
		isContainerOnScreen,
		isFirstSlideTracked,
		trackSlideChange,
	]);

	useEffect(() => {
		if (areAllImagesRendered) return;

		if (isCardActive) {
			setAreAllImagesRendered(true);
		}
	}, [areAllImagesRendered, isCardActive]);

	return (
		<StyledContainer
			ref={containerRef}
			$shouldDisablePointerEvents={shouldDisablePointerEvents}
		>
			<Swiper
				loop
				swiperRef={slider}
				onSlideChanged={onSlideChanged}
				nextPrevControls={isDesktop && isCardActive ? 'show' : 'hide'}
				nextPrevControlSize='small'
				nextPrevControlPosX='0.8rem'
				autoPlay={isCardActive && !isDesktop && hasPageLoaded}
				autoPlayTimeMs={autoPlayTimeMs}
				beforeChangeCallback={setCurrentPaginationIndex}
				allowLoopOnMobile
			>
				{productImages.map((image, i) =>
					video?.url && i === 0 ? (
						<Video
							key={'video-0'}
							url={video.url}
							fallbackImage={video.fallbackImage}
							width={width}
							height={height}
							currentlyPlaying={
								currentIndex === 0 && isCardActive
							}
							eventTracking={true}
							aria={{
								label: video.fallbackImage.altText,
							}}
							preventVideoClickEventPropagation={false}
						/>
					) : (
						<Image
							key={`image-${i}`}
							className='core-carousel-image'
							src={image.url}
							width={width}
							height={height}
							alt={image?.altText ?? image?.alt ?? ''}
							title={image?.title}
							layout={'responsive'}
							priority={priority && i === 0}
							loading={
								// load next image in carousel.
								isCardActive &&
								i === currentPaginationIndex + 1 &&
								!(priority && i === 0)
									? 'eager'
									: undefined
							}
						/>
					),
				)}
			</Swiper>
			<Conditional if={shouldShowCarousel}>
				<PagintorContainer>
					<SwiperIndicator
						activeIndex={currentPaginationIndex}
						total={productImages.length}
						limit={dotsLimit}
						size={isDesktop ? 1 : 1.2}
						paginatorType={isDesktop ? 'Default' : 'TimedPaginator'}
						containerWidthOverride={
							isDesktop
								? undefined
								: (dotsLimit - 1) *
										(mobileDotSize + 2 * mobileDotMargin) +
								  (mobileTabSize + 2 * mobileTabMargin)
							// (dots - 1) * (dotSize + (2 * dotMargin)) + (tabSize + (2 * tabMargin))
						}
						marginOverride={isDesktop ? undefined : 6}
						activeSlideTimer={autoPlayTimeMs}
						tabSize={1.5}
						dotSize={0.375}
					/>
				</PagintorContainer>
			</Conditional>
			<Conditional if={isDesktop && shouldShowCarousel}>
				<LeftArrowShadow
					onClick={event =>
						onArrowShadowClick({ event, direction: 'left' })
					}
				/>
				<RightArrowShadow
					onClick={event =>
						onArrowShadowClick({ event, direction: 'right' })
					}
				/>
			</Conditional>
		</StyledContainer>
	);
};

export default ProductCardMediaCarousel;
