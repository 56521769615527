import { useEffect, useState } from 'react';

import { DOCUMENT_READY_STATES } from 'Constants/constants';

const usePageLoaded = () => {
	const [isPageLoaded, setIsPageLoaded] = useState(false);

	useEffect(() => {
		const handleLoad = () => {
			setIsPageLoaded(true);
		};

		if (
			document.readyState === DOCUMENT_READY_STATES.INTERACTIVE ||
			document.readyState === DOCUMENT_READY_STATES.COMPLETE
		) {
			handleLoad();
		} else {
			window.addEventListener('DOMContentLoaded', handleLoad);

			return () => {
				window.removeEventListener('DOMContentLoaded', handleLoad);
			};
		}
	}, []);

	return isPageLoaded;
};

export default usePageLoaded;
